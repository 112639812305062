<template>
  <div>
    <v-data-table
      :items="filteredItems"
      :headers="headers"
    >

      <template v-slot:top>
        <v-row no-gutters class="pa-4">
          <span class="text-h3">
            Logs for
            <span class="accent--text">
              {{ name }}{{ version ? `:${version}` : '' }}
            </span>
            on {{ environment }}
          </span>
          <v-spacer />
          <v-select 
            label="Severity" multiple chips
            :items="severityLevels"
            v-model="severity"
            density="compact"
            return-object
          ></v-select>
          <v-btn color="primary" outlined @click="$emit('refresh')">
            <v-icon left dark v-text="'$reload'" />
            {{ labels.reload }}
          </v-btn>
        </v-row>
      </template>

      <template v-slot:item.severity="{ value }">
        <v-chip :color="getStyleForSeverity(value)">
          {{ value }}
        </v-chip>
      </template>

      <template v-slot:item.correlation_uid="{ value }">
        <v-chip
          v-if="value"
          outlined
          color="primary"
          :to="{
            name: 'correlation-logs',
            params: {
              environment,
              correlationUid: value,
            },
          }"
        >
          {{ value }}
        </v-chip>
        <pre v-else>None</pre>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { labels } from '@/assets/texts.json';

export default {
  name: 'logTable',
  props: {
    environment: {
      type: String,
      default: '',     
      required: true,
    },
    headers: {
      type: Array[Object],
      default: () => [{}],
      required: true,
    },
    logs: {
      type: Array[Object],
      default: () => [{}],
      required: true,
    },
    name: {
      type: String,
      default: '',
      required: true,
    },
    version: {
      type: String,
      default: '',
      required: false,
    },
  },
  data: () => ({
    labels,
    severity: ['DEBUG', 'INFO', 'WARN', 'ERROR', 'CRITICAL'],
    severityLevels: ['DEBUG', 'INFO', 'WARN', 'ERROR', 'CRITICAL']
  }),
  computed: {
    filteredItems() {
      return this.logs.filter((i) => {
        return !this.severity || (this.severity.includes(i.severity));
      })
    }
  },
  methods: {
    getStyleForSeverity(severity) {
      switch (severity) {
        case 'CRITICAL':
        case 'ERROR':
          return 'error';
        case 'WARN':
          return 'warning';
        case 'INFO':
          return 'success';
        default:
          return '';
      }
    },
    timestampToDate(timestamp) {
      return new Date(timestamp * 1000).toLocaleString();
    },
  },
};
</script>
