var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"items":_vm.filteredItems,"headers":_vm.headers},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{staticClass:"pa-4",attrs:{"no-gutters":""}},[_c('span',{staticClass:"text-h3"},[_vm._v(" Logs for "),_c('span',{staticClass:"accent--text"},[_vm._v(" "+_vm._s(_vm.name)+_vm._s(_vm.version ? (":" + _vm.version) : '')+" ")]),_vm._v(" on "+_vm._s(_vm.environment)+" ")]),_c('v-spacer'),_c('v-select',{attrs:{"label":"Severity","multiple":"","chips":"","items":_vm.severityLevels,"density":"compact","return-object":""},model:{value:(_vm.severity),callback:function ($$v) {_vm.severity=$$v},expression:"severity"}}),_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":function($event){return _vm.$emit('refresh')}}},[_c('v-icon',{attrs:{"left":"","dark":""},domProps:{"textContent":_vm._s('$reload')}}),_vm._v(" "+_vm._s(_vm.labels.reload)+" ")],1)],1)]},proxy:true},{key:"item.severity",fn:function(ref){
var value = ref.value;
return [_c('v-chip',{attrs:{"color":_vm.getStyleForSeverity(value)}},[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"item.correlation_uid",fn:function(ref){
var value = ref.value;
return [(value)?_c('v-chip',{attrs:{"outlined":"","color":"primary","to":{
          name: 'correlation-logs',
          params: {
            environment: _vm.environment,
            correlationUid: value,
          },
        }}},[_vm._v(" "+_vm._s(value)+" ")]):_c('pre',[_vm._v("None")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }